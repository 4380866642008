import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import { Component, OnDestroy, OnInit, Self } from '@angular/core';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { AppLayout } from '@app/core/models/enums/app-layout.enum';
import { NavMenuMode } from '@app/core/models/enums/nav-menu-mode.enum';
import { AnalyticsService } from '@app/core/services/analytics.service';
import { AppLayoutService } from '@app/core/services/app-layout.service';
import { BigCommerceSelfServiceState } from '@app/core/services/bigcommerce-self-service-state.service';
import { LinkService } from '@app/core/services/link.service';

import { KeyboardShortcutService } from '@app/keyboard-shortcuts/services/keyboard-shortcut.service';
import { NgbPopoverConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { filter, Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        AppLayoutService
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    public viewHeight: boolean = false;

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    protected modeType: typeof NavMenuMode = NavMenuMode;
    protected mode: NavMenuMode;

    constructor(
        @Self() private readonly appLayoutService: AppLayoutService,
        private readonly analyticsService: AnalyticsService,
        protected readonly appStateService: AppStateService,
        protected readonly bigcommerceSelfService: BigCommerceSelfServiceState,
        private readonly fdxUI: FdxUI,
        private readonly keyboardShortcut: KeyboardShortcutService,
        private readonly linkService: LinkService,
        private readonly ngbTooltipConfig: NgbTooltipConfig,
        private readonly ngbPopoverConfig: NgbPopoverConfig,
        private readonly router: Router
    ) {
    }

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                tap(() => {
                    this.analyticsService.identify();
                    this.mode = this.getNavMenuMode()
                }),
                takeUntil(this.unsubscribe$)
            ).subscribe();

        this.appLayoutService.layout$
            .pipe(
                tap((layout) => {
                    this.viewHeight = layout === AppLayout.ViewHeight;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();

        this.setTooltipsDefaultConfig();
        this.setPopoversDefaultConfig();
        this.keyboardShortcut.init();

    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setTooltipsDefaultConfig(): void {
        this.ngbTooltipConfig.openDelay = 500;
    }

    private setPopoversDefaultConfig(): void {
        this.ngbPopoverConfig.openDelay = 500;
    }

    private getNavMenuMode(): NavMenuMode {
        if (this.isRouteActive(['/'], true)
            || this.isRouteActive(this.linkService.connectChannelAuthLink, true)
            || this.isRouteActive(this.linkService.connectChannelConfigLink, true)) {
            return NavMenuMode.HIDDEN;
        }

        /**
         * Show the normal horizontal menu if the Url page does not require an account previously set
         * This is required because we cannot determine account level feature flag for BigCommerce Self Service
         */
        if (this.isUrlWithoutAccountData()) {
            return NavMenuMode.NORMAL;
        }

        if (this.fdxUI.showMinimalEbayUI()) {
            return NavMenuMode.EBAY;
        }

        if (this.bigcommerceSelfService.showRestrictedUI()) {
            return NavMenuMode.VERTICAL;
        }

        return NavMenuMode.NORMAL;
    }

    private isUrlWithoutAccountData(): boolean {
        const profile = this.isRouteActive(this.linkService.profileLink, true);
        const billing = this.isRouteActive(this.linkService.billingLink, true);
        const releaseNotes = this.isRouteActive(this.linkService.releaseNotesLink, true);
        const utilities = this.isRouteActive(this.linkService.utilitiesLink, true);
        const cheatSheet = this.isRouteActive(this.linkService.cheatSheetLink, true);
        const keyboardShortcuts = this.isRouteActive(this.linkService.keyboardShortcutLink, true);
        const ebaySelection = this.isRouteActive(this.linkService.ebaySelectionLink, true);
        const accounts = this.isRouteActive(this.linkService.accountsLink, true);
        const demoBootstrap = this.isRouteActive(['/demos/bootstrap'], true);
        const demoInputs = this.isRouteActive(['/demos/inputs'], true);
        const demoAgGrid = this.isRouteActive(['/demos/ag-grid'], true);
        return profile
            || billing
            || releaseNotes
            || utilities
            || cheatSheet
            || keyboardShortcuts
            || ebaySelection
            || accounts
            || demoBootstrap
            || demoInputs
            || demoAgGrid;
    }


    private isRouteActive(route: string[], exact: boolean = false): boolean {
        const tree: UrlTree = this.router.createUrlTree(route);
        return this.router.isActive(
            tree,
            {
                paths: exact ? 'exact' : 'subset',
                queryParams: 'ignored',
                fragment: 'ignored',
                matrixParams: 'ignored'
            }
        );
    }
}
